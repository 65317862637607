<template>
  <div class="app">
    <el-cascader
      v-model="id"
      :options="options"
      @change="handleChange"
      size="small"
      style="width: 200px!important;"
    />
  </div>
</template>

<script>
import { industry } from "@/api/newCrm.js";
export default {
  name: '',
  components: {},
  props: {
    id: {
      type: Number,
      default: null,
    }
  },
  data () {
    return {
      options:[],
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    this.init();
  },
  methods: {
    init(){
      industry().then(res => {
        if(res.data.msg == 'success'){
          this.options = res.data.data.data;
        }
      });
    },
    changeValue(e) {
      this.$emit("update:id", this.id);
      this.$emit("success", this.id);
    },
  }
}
</script>

<style scoped lang="less"></style>
