<template>
  <div class="app">
    <!-- 新增线索弹窗 -->
    <el-drawer
      v-model="addDrawer"
      :before-close="handleClose"
      :close-on-click-modal="false"
      size="40%"
    >
      <template #header>
        <div class="drawerHead">
          <div>
            新增
          </div>
          <div>
            <el-button
              type="primary"
              @click="saveCule"
              size="small"
              style="margin-right: 5px"
              >保存</el-button
            >
          </div>
        </div>
      </template>
      <template #default>
        <el-form :model="form" :rules="rules" ref="form" label-width="120px" :inline="true">
          <!-- 基础信息 -->
          <div style="margin-top: 10px;">
            <div class="formTitle">
              基础信息
            </div>
            <div>
              <el-form-item label="客户名称:" prop="customerName">
                <el-input v-model="form.customerName" placeholder="请输入客户名称" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="跟进负责人:">
                <selectuser code="addCustomer" v-model:userId="form.followUserId" :multiples="false" style="width: 200px;margin: 0;" ></selectuser>
              </el-form-item>
              <el-form-item label="客户联系人:">
                <el-input v-model="form.customerContacter" placeholder="请输入客户联系人" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="联系人手机:" prop="customerContacterMobile">
                <el-input v-model="form.customerContacterMobile" placeholder="请输入联系人手机" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="客户来源:">
                <el-select
                  v-model="form.customerSource"
                  placeholder="请选择客户来源"
                  filterable
                  size="small"
                  style="width: 200px; margin: 0"
                >
                  <el-option
                    v-for="item in customerStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="来源说明:">
                <el-input v-model="form.sourceComment" placeholder="请输入来源说明" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="意向服务类型:">
                <el-radio-group v-model="form.serviceType" style="width: 200px; margin: 0">
                  <el-radio :label="1">代账</el-radio>
                  <el-radio :label="2">增值</el-radio>
                </el-radio-group>
                <!-- <el-checkbox-group v-model="form.serviceType" style="width: 200px; margin: 0">
                  <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </el-checkbox-group> -->
              </el-form-item>
              <el-form-item label="线索等级:">
                <select-clue
                  v-model:clueStatus="form.level"
                  placeholder="请选择"
                  :type="'level'"
                  style="width: 200px; margin: 0"
                />
                <!-- <el-select
                  v-model="form.level"
                  placeholder="请选择线索等级"
                  filterable
                  style="width: 200px; margin: 0"
                  size="small"
                >
                  <el-option
                    v-for="item in culeGradeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="备注:">
                <el-input v-model="form.remark" placeholder="请输入备注" size="small" style="width: 552px; margin: 0" clearable />
              </el-form-item>
            </div>
          </div>

          <!-- 企业信息 -->
          <div style="margin-top: 30px;">
            <div class="formTitle">
              企业信息
            </div>
            <div>
              <el-form-item label="所在地区:">
                <addressList v-model:type="form.customerDistrict" style="width:200px;margin: 0;"></addressList>
              </el-form-item>
              <el-form-item label="办公地址:">
                <el-input v-model="form.customerAddress" placeholder="请输入办公地址" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="法定代表人:">
                <el-input v-model="form.legalPerson" placeholder="请输入法定代表人" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="成立日期:">
                <el-date-picker
                  style="width:200px;"
                  size="small"
                  v-model="form.incorporationTime"
                  type="date"
                  placeholder="请选择成立日期"
                  clearable
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <el-form-item label="注册资本:">
                <el-input v-model="form.registeredCapital" placeholder="请输入注册资本" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="纳税人资格:">
                <el-select
                  v-model="form.taxpayerRight"
                  placeholder="请选择纳税人资格"
                  style="width: 200px; margin: 0"
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="item in taxpayerList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="统一社会信用代码:">
                <el-input v-model="form.socialCode" placeholder="请输入统一社会信用代码" size="small" class="w200" clearable />
              </el-form-item>
              <el-form-item label="国家标准行业:">
                <!-- <el-select
                  v-model="form.standardIndustry"
                  placeholder="请选择国家标准行业"
                  style="width: 200px; margin: 0"
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="item in customerStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
                <selectindustry v-model:id="form.standardIndustry"></selectindustry>
              </el-form-item>
              <el-form-item label="经营范围:">
                <el-input v-model="form.businessScope" placeholder="请输入经营范围" size="small" style="width: 552px; margin: 0" clearable />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import { CUSTOMER_STATUS,CULE_GRADE,TRAXPAYER } from "@/utils/commonData";
import { addClue } from "@/api/newCrm.js";
import addressList from "@/components/Screening/address";
import selectindustry from "./selectIndustry.vue"

export default {
  name: 'infoForm',
  components: {
    addressList,
    selectindustry
  },
  props: {},
  data () {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人手机"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      form: {},//表单数据
      customerStatusList: CUSTOMER_STATUS.filter(item => item.label !== "全部"),//客户来源
      culeGradeList: CULE_GRADE,//线索等级
      taxpayerList: TRAXPAYER,//纳税人资格
      rules: {
        customerName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' },
        ],
        customerContacterMobile: [
          { required: true, validator: validateMobile, trigger: "blur" }
        ],
      },
      options: [
        { label: "代账", value: 1 },
        { label: "增值", value: 2 },
      ],
      addDrawer: false,
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    // 初始化
    init(){
      this.form = {}
      this.addDrawer = true
    },
    saveCule(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          addClue(this.form).then(res => {
            console.log(res);
            if (res.data.msg == 'success') {
              this.$qzfMessage("保存成功")
              this.$emit('success')
              this.addDrawer = false
            }
            // this.options = res.data.data.list
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.formTitle{
  font-weight: bold;
  margin-bottom: 20px;
}
.w200{
  width: 200px;
}
.drawerHead{
  display: flex;
  justify-content: space-between;
}
</style>
