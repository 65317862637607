<template>
  <div class="app">
    <el-dialog
      :close-on-click-modal="false"
      v-model="daoruVisible"
      title="导入"
      width="40%"
      destroy-on-close
    >
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input
            style="width: 70%; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <a href="javascript:;" @click="loadForm()">下载模板</a>
        <div style="text-align: right">
          <el-button
            size="small"
            type="primary"
            @click="uploadExcel"
            v-loading="daoruLoading"
            >上传</el-button
          >
        </div>
      </div>
    </el-dialog>

    <daoruContract ref="daoruContract" />
  </div>
</template>

<script>
import daoruContract from "../../../crm/contract/components/daoruContract.vue";

export default {
  name: '',
  components: {
    daoruContract
  },
  props: {},
  data () {
    return {
      daoruVisible: false,
      path: '',
      daoruLoading: false,
      rawFile:'',
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    init(){
      console.log('111');
      this.daoruVisible = true;
      this.path = '';
      this.rawFile = '';
    },
    // 下载模板
    loadForm() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/合同导入模板.xlsx" +
          "?" +
          new Date().getTime()
      );
    },

    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },

    // 选择文件
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },

    // 上传文件
    uploadExcel() {
      if(!this.rawFile){
        this.$qzfMessage('请选择文件',1)
        return
      }
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      

      this.daoruLoading = false;
      this.path = ''
      this.rawFile = '';
      this.$emit('success')
      this.$qzfMessage("上传成功")
      this.daoruFormVisible = false;
      this.$refs.daoruContract.init(res.data.data);
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
  }
}
</script>

<style scoped lang="less"></style>
